// src/pages/Contact.js
import React, { useState } from 'react';
import './Contact.css';
import NewsLetter from '../components/Newsletter.js';


function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <section className="contact">
      <div className="container">
        <h2>Kontaktirajte nas</h2>
        <h3>Mi svakome prilazimo individualno i vjerujemo da je tako najbolje. Ostavite nam Vaše podatke i mi ćemo vam se javiti u roku od 24h kako bismo Vas upoznali sa prilikama koje vas čekaju na našoj platformi.</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Ime:</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Poruka:</label>
            <textarea id="message" name="message" value={formData.message} onChange={handleChange} required />
          </div>
          <button type="submit">Pošalji</button>
        </form>
        <h2 className="newsletter">Pretplatite se besplatno na novosti iz Galerije</h2>
        <NewsLetter />
      </div>
    </section>
  );
}

export default Contact;
