import React, { useState, useEffect, useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import './BlogItem.css';
import logo from '../fonts/logo bez teksta bijeli.png'


function BlogCard({ blog, reverse }) {
    const navigate = useNavigate();
    const cardRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.unobserve(entry.target);
            }
          },
          {
            threshold: 0.2,
          }
        );
        
        if (cardRef.current) {
          observer.observe(cardRef.current);
        }
    
        return () => {
          if (cardRef.current) {
            observer.unobserve(cardRef.current);
          }
        };
      }, []);  

    const handleClick = () => {
        navigate(`/blog/${blog.name}`, { state: { blogData: blog } });
    };

    return (
        <div ref={cardRef} className={`blog-card ${reverse ? 'reverse' : ''} ${isVisible ? 'show' : ''}`} onClick={handleClick} style={{ '--card-index': blog.id }}>
            <div className="blog-card-image" style={{ backgroundImage: `url(${blog.imageUrl})` }}>
                <div className="logo-container">
                    <img src={logo} alt="Your Logo" className="logo" />
                </div>
                <div className="blog-card-overlay">
                    <h3 className="blog-title">{blog.name}</h3>
                    <p>{blog.description}</p>
                </div>
            </div>
        </div>
    );
}

export default BlogCard;
