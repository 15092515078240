import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import './ArtistProfile.css';
import { FaFacebookF, FaInstagram, FaBehance } from 'react-icons/fa';


function ArtistProfile() {
    const location = useLocation();
    const { artist } = location.state;
    const [artistData, setArtistData] = useState(artist);
    const navigate = useNavigate();


    useEffect(() => {
        const API_URL = process.env.NODE_ENV === 'production'
            ? 'https://digitalnagalerija.ba'
            : 'http://localhost:3001';  

            const fetchArtist = async () => {
                if (!artist?.artwork) {
                    try {
                        const response = await fetch(`${API_URL}/api/artists/${artist.id}/artwork`); 
                        if (response.ok) {
                            const artwork = await response.json();
                            setArtistData({ ...artist, artwork });
                        } else {
                            throw new Error('Could not fetch artist details');
                        }
                    } catch (error) {
                        console.error('Error fetching artist details:', error);
                    }
                }
            };
    
            fetchArtist();
        }, [artist]); 

    if (!artistData) {
        return <div className="artist-bio">Podaci o umjetniku nisu pronađeni, molimo Vas pokušajte kasnije.</div>;
    }


    const handleBackClick = () => {
        navigate(-1);
    };

    const socialMediaIcons = {
        facebook: <FaFacebookF />,
        instagram: <FaInstagram />,
        behance: <FaBehance />
    };

    const handleClick = (artistId) => {
        navigate(`/artwork/${artistId}`);
    };

    return (
        <section className="artist-profile">
            <button className="back-button" onClick={handleBackClick}>
                &larr;
            </button>
            
            <img
                src={artistData?.imageUrl}
                alt={artistData?.name}
                className="artist-background-image"
            />
           <h3 className="artist-popular-work">Popularni radovi</h3>
            {artistData?.artwork && artistData.artwork.length > 0 ? (
                <div className="artwork-carousel">
                    <Carousel 
                        showDots={false}
                        showArrows={false}
                        enableSwipe={true}
                        itemsToShow={3}
                    >
                        {artistData.artwork.map(artwork => (
                            <div key={artwork._id} className="carousel-image-container" onClick={() => handleClick(artwork._id)}>
                                <img src={artwork.imageUrl} alt={artwork.title} className="carousel-image" />
                                <div className="carousel-image-overlay">
                                    <p className="carousel-image-title">{artwork.title}</p>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            ) : (
                <p className="artist-bio">Molimo Vas da pretražite radove ovog umjetnika na galeriji radova.</p>
            )}
            <h2 className="artist-bio-name">{artistData?.name}</h2>
            <div className="artist-bio">
                <p>{artistData?.bio}</p>
            </div>
            <div className="social-media-card">
                <h3>Pogledajte umjetnika i na društvenim mrežama</h3>
                <ul className="social-links">
                    {artistData?.socialLinks && Object.entries(artistData.socialLinks).map(([platform, link]) => (
                        link && (
                            <li key={platform}>
                                <a href={link} target="_blank" rel="noopener noreferrer">
                                    {socialMediaIcons[platform]}
                                </a>
                            </li>
                        )
                    ))}
                </ul>
            </div>
            <h2 className='comming-soon'>USKORO I NA DESKTOP PREGLEDU</h2>
        </section>
    );
}

export default ArtistProfile;