import GalleryCard from './Gallery.js';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './GalleryItem.css'
import Spacer from '../components/Spacer.js';
import BackgroundVideo from '../components/BackgroundVideo.js';
import video from '../fonts/blogimages/manart.mp4';
import { useTranslation } from 'react-i18next';






function GalleryItem({ }) {
    const [filteredArtists, setFilteredArtists] = useState([]);
    const [activeFilter, setActiveFilter] = useState('Svi Stilovi');
    const [activeStyleFilter, setActiveStyleFilter] = useState('Svi Stilovi');
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [uniqueStyles, setUniqueStyles] = useState(['Svi Stilovi']);
    const [activePriceFilter, setActivePriceFilter] = useState(null);
    const [artworks, setArtworks] = useState([]);
    const [artists, setArtists] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
    const [activeFilters, setActiveFilters] = useState({
        genre: 'Svi Stilovi',
        price: null,
    });

    const [error, setError] = useState(null);

    const priceRanges = [
        /* { label: 'Svi', filter: (artist) => artist.price },
        { label: 'Ispod 100 KM', filter: (artist) => artist.price < 100 },
        { label: '100-350 KM', filter: (artist) => 100 <= artist.price && artist.price < 350 },
        { label: 'Iznad 350 KM', filter: (artist) => 350 <= artist.price && artist.price < 1500 }, */

        { label: 'Svi', filter: () => true }, // Always return true if no filter is selected
        { label: 'Ispod 100 KM', filter: (artist) => artist.price < 100 },
        { label: '100-350 KM', filter: (artist) => 100 <= artist.price && artist.price < 350 },
        { label: 'Iznad 350 KM', filter: (artist) => 350 <= artist.price && artist.price < 1500 },
    ];


    const artistListRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);


    useEffect(() => {
        const fetchArtworks = async () => {
            setIsLoading(true);
            try {
                const API_URL = process.env.NODE_ENV === 'production'
                    ? 'https://digitalnagalerija.ba'
                    : 'http://localhost:3001';

                const response = await fetch(
                    `${API_URL}/api/artists?page=${currentPage}&perPage=10`
                );
                if (!response.ok) {
                    throw new Error('Could not fetch artists');
                }
                
                const data = await response.json();
                console.log("Artworks State:", data);

                setTotalPages(data.totalPages);
                const allArtworks = data.artworks;
                setArtworks(allArtworks);
                const allStyles = ['Svi Stilovi', ...new Set(data.artworks.map(artwork => artwork.genre))];
                setUniqueStyles(allStyles);
                console.log('stilovi 1', allStyles)
                setError(null);
            } catch (error) {
                console.error('Error fetching artworks:', error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchArtworks();
    }, [currentPage]);




    useEffect(() => {

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5 // Trigger when 50% of the item is visible
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        }, options);

        if (artistListRef.current) {
            observer.observe(artistListRef.current);
        }

        return () => {
            if (artistListRef.current) {
                observer.unobserve(artistListRef.current);
            }
        };
    }, [activeStyleFilter, activePriceFilter, artworks, artists]);


    const filteredArtworks = artworks?.filter((artwork) => {
        console.log('radovi 1', artwork)
       /*  const matchesStyle = activeStyleFilter === 'Svi Stilovi' || artwork.genre === activeStyleFilter;
        const matchesPrice = !activePriceFilter || activePriceFilter.filter(artwork);
        return matchesStyle && matchesPrice; */
        const matchesStyle = activeStyleFilter === 'Svi Stilovi' || artwork.genre === activeStyleFilter;
        const matchesPrice = activePriceFilter ? activePriceFilter.filter(artwork) : true; // Handle null case
        return matchesStyle && matchesPrice;
    });

    const visiblePages = 5; 
    const halfVisible = Math.floor(visiblePages / 2);

    let startPage = Math.max(1, currentPage - halfVisible);
    let endPage = Math.min(totalPages, startPage + visiblePages - 1);

    if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - visiblePages + 1);
    }



    return (
        <>
            <BackgroundVideo video={video} className="video-gallery" />
            <h1 className="artist-list-title">{t('digital_gallery')}</h1>
            <h2 className="artist-list-intro">{t('gallery_intro')}</h2>

            <button className="filter-toggle" onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}>
                {isFilterMenuOpen ? <span>Filteri</span> : <span>Filteri</span>}
            </button>
            <div className={`filter-menu ${isFilterMenuOpen ? 'open' : ''}`}>


                <div className="filter-options">
                    <button className="close-button" onClick={() => setIsFilterMenuOpen(false)}>
                        &times;
                    </button>
                    <div className="filter-section">
                        <h3>{t('genre')}</h3>
                        <div className="filter-buttons">
                            {uniqueStyles.map((stil) => (
                                <button
                                    key={stil}
                                    onClick={() => setActiveStyleFilter(stil)}
                                    className={activeFilters.genre === stil ? 'active' : ''}
                                >
                                    {stil}
                                </button>
                            ))}
                        </div>
                    </div>
                    <hr className="filter-separator" />

                    <div className="filter-section">
                        <h3>{t('price')}</h3>
                        <div className="filter-buttons">
                            {priceRanges.map((range) => (
                                <button
                                    key={range.label}
                                    onClick={() => setActivePriceFilter(range)}
                                    className={activeFilters.price === range ? 'active' : ''}
                                >
                                    {range.label}
                                </button>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
            <Spacer height={30} />
            <div className={`artist-list ${isVisible ? 'show' : ''}`} ref={artistListRef}>

                {isLoading ? (
                    <div className="artist-list-title">Stranica se učitava...</div>
                ) : error ? (
                    <div>Error: {error}</div>
                ) : filteredArtworks.length > 0 ? (
                    filteredArtworks.map((artwork) => (
                        <GalleryCard key={artwork._id} artwork={artwork} />
                    ))
                ) : (
                    <div className="artist-list-title">Umjetnici nisu pronađeni.</div>
                )}
            </div>
            <div className="pagination">

                <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                {t('previous')}
                </button>

                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                    <button
                        key={startPage + index}
                        onClick={() => setCurrentPage(startPage + index)}
                        className={currentPage === startPage + index
                            ? 'active' : ''}
                    >
                        {startPage + index}
                    </button>
                ))}

                <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
                {t('next')}
                </button>
    
            </div>
            <Spacer height={80} />

        </>
    );
}

export default GalleryItem;
