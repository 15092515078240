import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Profile.css';



function ArtistCard({ artist }) {
    const navigate = useNavigate();

    const [cardHeight, setCardHeight] = useState(null);

    useEffect(() => {
        const height = 290;

        setCardHeight(height);
    }, [artist.artistId]);


    const handleClick = () => {
        navigate(`/pu/${artist.artistId}`, { state: { artist } });
    };

    return (
        <div className="artist-card" onClick={handleClick} style={{ height: cardHeight ? `${cardHeight}px` : 'auto' }} >
            <div className="image-container">
                <img src={artist.imageUrl} alt={artist.name} className="artist-image" />
                <div className="image-overlay">
                    <h3 className="artist-name">{artist.name}</h3>
                    <h4 className="artist-city">{artist.city}</h4>
                </div>
            </div>
        </div>
    );
}

export default ArtistCard;
