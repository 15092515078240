import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './PaintingDetails.css';

function PaintingDetails() {
    const { paintingId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [paintingData, setPaintingData] = useState(
        location.state?.artwork || null
    );


    const handleBuyClick = () => {
        navigate('/kupi');
    };

    const API_URL = process.env.NODE_ENV === 'production'
        ? 'https://digitalnagalerija.ba'
        : 'http://localhost:3001';

        useEffect(() => {
            const fetchPainting = async () => {
              try {
                const artworkFromLocationState = location.state?.artwork
                console.log('test', artworkFromLocationState)
        
                if (
                  artworkFromLocationState &&
                  artworkFromLocationState.id.toString() === paintingId
                ) {
                  setPaintingData(artworkFromLocationState);
                } else {
                    console.log('failed to')
                  const response = await fetch(`${API_URL}/api/artists`);
                  if (response.ok) {
                    const artistsData = await response.json();
                    const allArtworks = artistsData.flatMap((artist) => artist.artwork);
                    const artwork = allArtworks.find(
                      (art) => art._id.toString() === paintingId
                    );
                    if (artwork) {
                      setPaintingData(artwork);
                    } else {
                      setError("Could not find artwork.");
                    }
                  } else {
                    throw new Error("Could not fetch artists");
                  }
                }
              } catch (error) {
                setError(error.message);
              } finally {
                setIsLoading(false);
              }
            };
        
            fetchPainting();
          }, [paintingId, location.state]);



    if (!paintingData) {
        return <div className="art-description-title">Umjetnina nije pronađena</div>;
    }

    return (
        <section className="painting-details">

            <div className="painting-content">
                <div className="painting-image-container">
                    <img src={paintingData.imageUrl} alt={paintingData.title} className="painting-image" />
                </div>
                <div className="painting-info">
                    <h2>{paintingData.title}</h2>

                    <table className="painting-details-table">
                        <tbody>
                            <tr>
                                <th>Žanr:</th>
                                <td>{paintingData.genre}</td>
                            </tr>
                            <tr>
                                <th>Cijena:</th>
                                <td>{paintingData.price}</td>
                            </tr>
                            <tr>
                                <th>Dimenzije:</th>
                                <td>{paintingData.dimensions}</td>
                            </tr>
                            <tr>
                                <th>Lokacija:</th>
                                <td>{paintingData.location}</td>
                            </tr>
                            <tr>
                                <th>Godina:</th>
                                <td>{paintingData.year}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h3 className="art-description-title">Opis</h3>
                    <div className="art-description">{paintingData.description}</div>
                </div>
                <button className="buy-button" onClick={handleBuyClick}>
                    Kupi
                </button>
            </div>
            <h2 className='comming-soon'>USKORO I NA DESKTOP PREGLEDU</h2>
        </section>
    );
}

export default PaintingDetails;
